<template>
  <div class="">
    <div class="banner">
      <img src="https://site-cdn.huami.com/files/amazfit/amazfit-cn/count-down/20200922/countdown-mobile-banner.png" alt="">

      <div class="home">
        <a href="/">进入Amazfit官网</a>
      </div>
      <article v-if="!showLive">
        <div id="countdown">
          <countdown :time="coutdown" @end="end">
            <template slot-scope="props">
              <div>
                <span class="countdown-section">
                  <span class="countdown-amount days">{{props.days}}</span>
                  <span class="countdown-period">天</span>
                </span>
                <span class="countdown-section">
                  <span class="countdown-amount hours">{{props.hours}}</span>
                  <span class="countdown-period">时</span>
                </span>
                <span class="countdown-section">
                  <span class="countdown-amount minutes">{{props.minutes}}</span>
                  <span class="countdown-period">分</span>
                </span>
                <span class="countdown-section">
                  <span class="countdown-amount seconds">{{props.seconds}}</span>
                  <span class="countdown-period">秒</span>
                </span>
              </div>
            </template>
          </countdown>
        </div>
      </article>
    </div>

    <div class="live" v-if="showLive">
      <iframe src="https://www.iqiyi.com/kszt/huami0922.html" frameborder="0"></iframe>
    </div>

    <div class="products">
      <div class="item">
        <a href="https://item.jd.com/100015491214.html">
          <img  src="https://site-cdn.huami.com/files/amazfit/amazfit-cn/count-down/20200922/moblie-GTS2.png">
        </a>
      </div>
      <div class="item">
        <a href="https://item.jd.com/100015491216.html">
          <img src="https://site-cdn.huami.com/files/amazfit/amazfit-cn/count-down/20200922/mobile-GTR2.png" alt="">
        </a>
      </div>
    </div>

    <div class="logos">
      <img src="https://site-cdn.huami.com/files/amazfit/amazfit-cn/count-down/20200922/mobile-logos.png" alt="">
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'page20200922',
  data () {
    return {
      coutdown: moment([2020, 8, 22, 14, 0, 0]).diff(moment()),
      showLive: false
    }
  },
  created () {
    console.log(this.coutdown)
  },
  methods: {
    end () {
      this.showLive = true
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Roboto Thin';
  font-style: normal;
  font-weight: 100;
  src: url(https://site-cdn.huami.com/files/common/fonts/roboto-thin_5ff7d830ec26a.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url(https://site-cdn.huami.com/files/common/fonts/roboto_5ff7d831041ef.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  color: #FFF;
  font-size: 12px;
  text-decoration: none;
}

.banner {
  position: relative;
  max-width: none;
  padding: 0;
  text-align: center;

  img {
    display: block;
    width: 100%;
  }

  .home {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: 'FZLTCH', sans-serif;
  }

  .home a {
    border: 1px solid #FFF;
    padding: 15px 20px;
    border-radius: 30px;
    font-size: 0.4rem;
  }

  article {
    position: absolute;
    left: 0;
    top: 70%;
    width: 100%;
    transform: translate(0, -50%);
  }
}

#countdown {
  margin-top: 4rem;
  text-align: center;
  width: 100%;
}

.countdown-section {
  display: inline-block;
  width: 80px;
  padding: 0 0;
  text-align: center;
}

.countdown-amount {
  font-size: 1.2rem;
  font-family: 'FZLTXH', sans-serif;
  font-weight: 200;
  text-shadow: 1px 2px 6px #d3aea5;
}

.countdown-period {
  font-size: 1.2em;
  display: inline-block;
  width: 100%;
  font-family: "FZLTXH";
  font-weight: 100;
  text-shadow: 1px 2px 6px #d3aea5;
}

.live {
  background: #F5F5F5;
  padding: 15px;
  margin: 0;
  width: auto;

  iframe {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 200px;
  }
}

.products {
  padding: 15px;
  margin: 0;
  width: auto;

  .item {
    margin-top: 1rem;

    img {
      width: 100%;
    }
  }
}

.logos {
  padding: 15px;
  margin: 0;
  width: auto;

  img {
    width: 100%;
  }
}
</style>
